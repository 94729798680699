import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useAuth } from '@hooks';
import { LayoutMissionMobile, LayoutMissionDesktop } from '@components';
import { BudgetMission2MainTab } from '@components';
import { MissionsInfo } from '@data';
import { AppContext } from '@context';
import { nextMissionModalInfo } from '@utils';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

const BudgetMission1 = () => {
  const isMobile = useMediaQuery('(max-width: 1240px)');
  useAuth({ redirectIfNotLogged: true });
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.BudgetCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m1.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const onHouseClick = useCallback(() => {
    navigate(Route.budget);
  }, []);

  return (
    <>
      {isMobile ? (
        <LayoutMissionMobile
          title={'Importancia del presupuesto'}
          confettiFire={confettiFire}
          number={1}
          onHouseClick={() => navigate(Route.budget)}
        >
          <BudgetMission2MainTab handleClick={handleClick} />
        </LayoutMissionMobile>
      ) : (
        <LayoutMissionDesktop
          title={'Importancia del presupuesto'}
          confettiFire={confettiFire}
          number={1}
          onHouseClick={onHouseClick}
        >
          <BudgetMission2MainTab handleClick={handleClick} />
        </LayoutMissionDesktop>
      )}
    </>
  );
};

export default BudgetMission1;
